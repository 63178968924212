<template>
	<div style="padding: 20px">
		<div class="flex justify-between">
			<el-form :inline="true" :model="searchForm">
				<el-form-item label="用户查询">
					<el-input v-model="searchForm.search" placeholder="请输入用户名/手机号"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" icon="el-icon-search" @click="search(1)">查询</el-button>
				</el-form-item>
			</el-form>
			<!-- 操作按钮 -->
			<el-form :inline="true">
				<el-form-item>
					<el-button type="primary" @click='exportProduct()'>导出</el-button>
				</el-form-item>
			</el-form>
		</div>
		<el-table :data="tableData" v-loading="loading">
			<el-table-column type="index" width="50">
			</el-table-column>
			<el-table-column prop="created_at" label="日期">
			</el-table-column>
			<el-table-column prop="product_code" label="货号">
			</el-table-column>
			<el-table-column prop="name" label="品名">
			</el-table-column>
			<el-table-column prop="number" label="数量">
			</el-table-column>
			<el-table-column prop="price" label="价格">
			</el-table-column>
			<el-table-column prop="sale_name" label="销售">
			</el-table-column>
			<el-table-column prop="remark" label="备注">
			</el-table-column>
		
		


		
		</el-table>
		<div class="text-center margin-top">
			<el-pagination @current-change="pageChange" :current-page="searchForm.page" :page-sizes="vuex_page_sizes"
				:page-size="searchForm.page_size" layout="total, sizes, prev, pager, next, jumper" :total="total"
				@size-change="pageSizeChange">
			</el-pagination>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				loading: false, // 表格加载状态
				tableData: [], // 数据
				searchForm: {
					search: '',
					page: 1, // 否 int 页码
					page_size: 10, // 否 int 页脚
				},
				total: 0,
			};
		},
		mounted() {
			// 查询数据
			this.search()
		},
		methods: {
			search() {
				this.loading = true;
				this.$api.tablewx.getProductSellList(this.searchForm).then(res => {
					console.log(res.data.data)
					this.total = res.data.total;
					this.tableData = res.data.data;
					this.loading = false;
				})
			},
			pageSizeChange(size) {
				this.searchForm.page_size = size;
				this.search()
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.search()
			},
			//导出数据
			exportProduct(){
				
				this.$api.tablewx.exportProductSellList(this.searchForm).then(res => {
					let a = document.createElement('a');
					let blob = new Blob([res], {
						type: "application/vnd.ms-excel"
					});
					let objectUrl = URL.createObjectURL(blob);
					a.setAttribute("href", objectUrl);
					a.setAttribute("download", '购买产品记录导出.xls');
					a.click();
				})
			},
			
		
		},
	};
</script>

<style scoped lang="scss">
	.cover {
		width: 40px;
		height: 40px;
		border-radius: 5px;
		overflow: hidden;
		margin-right: 10px;
	}
</style>
